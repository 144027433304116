<template>
  <v-card class="mx-auto" v-if="campaigns.length > 0">
    <v-carousel :continuous="true" hide-delimiter-background hide-delimiters height="382px">
      <v-carousel-item
        v-for="(item, i) in campaigns"
        :key="i"
        reverse-transition="fade-transition"
        transition="fade-transition"
      >
        <v-card-title
          >{{ $t("app.activeCampaigns") }} <v-spacer></v-spacer>
          <v-btn outlined small :to="{ name: 'list-campaigns' }">{{
            $t("app.checkAll")
          }}</v-btn></v-card-title
        >
        <campaign-card :item="item" :limit-text="limitText" :icon="icon" :namespace="namespace" />
      </v-carousel-item>
    </v-carousel>
  </v-card>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "CampaignsCarousel",
  computed: {
    ...mapGetters({
      loading: "options/loading"
    }),
    campaigns() {
      if (this.isAdmin()) {
        return this.$store.getters["campaigns/activeCampaigns"];
      } else {
        return this.$store.getters["campaigns/items"];
      }
    }
  },
  components: { CampaignCard: () => import("@/components/core/cards/CampaignCard") },
  mounted() {
    this.getData();
  },
  data() {
    return {
      limitText: "90",
      icon: "mdi-cellphone-wireless",
      namespace: "campaigns"
    };
  },
  methods: {
    async getData() {
      if (this.isAdmin()) {
        await this.$store.dispatch("campaigns/getActiveCampaigns");
      } else {
        await this.$store.dispatch("campaigns/getMyCampaigns");
      }
    }
  }
};
</script>
